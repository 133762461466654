<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder">IP SLA Latency</h3>
			<div class="col-3 d-flex align-items-center font-size-xs">* Values in ms</div>
		</div>
		<div class="card-body pt-2">
			<div class="card card-custom card-stretch gutter-b" v-for="d in graphs" v-bind:key="d.name">
				<div class="card-header border-1">
					<h3 class="card-title font-weight-bold">{{ d.name }}</h3>
				</div>
				<div class="card-body border-1">
					<dygraphs :data="d.data" :options="d.opts" />
				</div>
			</div>
			<div class="alert alert-secondary" v-if="propsnotset">Properties are not set for this widget.</div>
		</div>
	</div>
</template>

<script>
const date = new Date();
const offset = date.getTimezoneOffset() * 60000;

export default {
	name: 'DashSWIPSLALatency',
	data() {
		return {
			data: [],
			graphs: [],
			propsnotset : false
		};
	},
	components: {
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	methods: {
		processData() {
			if (!this.data || this.data.data.length == 0) {
				return;
			}
			this.graphs = [];
			this.data.data.forEach((d) => {
				var labels = ['Timestamp'];
				var ts = Object.keys(d.data);
				var keys = Object.keys(d.data[ts[0]]);
				keys.forEach((k) => {
					labels.push(this.data.names[k]);
				});
				var gd = [];
				ts.forEach((t) => {
					var e = [];
					e.push(new Date(t * 1000 + offset));
					keys.forEach((k) => {
						e.push(d.data[t][k]);
					});
					gd.push(e);
				});

				this.graphs.push({
					name: d.name,
					data: gd,
					opts: {
						labels: labels,
						includeZero: true,
						//		labelsDiv: ld,
						labelsKMG2: false,
						labelsSeparateLines: false,
						fillGraph: false,
					},
				});
			});
		},
	},
	created() {
		this.loaded = false;
		this.$http.get('dashboard/swipslalatency').then((resp) => {
			this.data = resp.data.data;
			if (this.data == 'Widget Properties not set for account.') {
				this.propsnotset = true;
			} else {
				this.processData();
			}
			this.loaded = true;
		});
	},
};
</script>

<style>
</style>